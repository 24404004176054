import React, { useEffect, useState } from 'react';
import SurveyComponent from './surveyGenerator';
import {localStoredData} from './surveyGenerator';

const App = () => {
  const [surveyId, setSurveyId] = useState('');
  const [surveyPostId, setSurveyPostId] = useState('');


  useEffect(() => {
    const handleUrlChange = () => {
      const url = new URL(window.location.href);
      const searchParams = new URLSearchParams(url.search);
      setSurveyId(searchParams.get('survey_id'));
      setSurveyPostId(searchParams.get('survey_post_id'));
    };


    // Listen to the 'popstate' event
    window.addEventListener('popstate', handleUrlChange);

    // Call the handler on initial load
    handleUrlChange();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('popstate', handleUrlChange);
    };
  }, []);
  
  const handleUploadOfflineResults = () => {
    localStoredData(surveyPostId);
  };

  return (
    <div>
      <div className="upload-button-container">
        <button className="upload-button" onClick={handleUploadOfflineResults}>
          Upload Offline Results
        </button>
      </div>
      <div className="survey-container">
        <SurveyComponent surveyId={surveyId} surveyPostId={surveyPostId} />
      </div>
    </div>
  );
};

export default App;
