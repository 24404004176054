import React, { useEffect, useState } from 'react';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import 'survey-core/defaultV2.min.css';
import './index.css';
import axios from 'axios';

let savingTextError =
  'You are OFFLINE. The survey results have been saved locally.\nPlease remember to press the "Upload Offline Results" button when you are back online. Meanwhile, you can start a new survey.';
let savingTextSuccess =
  'This survey has been saved. You can start a new one now.';


const postSurveyResult = (postId, surveyResult) => {
  const url = 'https://api.surveyjs.io/public/v1/Survey/post/';

  const data = {
    postId: postId,
    surveyResult: JSON.stringify(surveyResult),
  };

  return axios
    .post(url, data)
    .then((response) => response.status)
    .catch((error) => {
      throw error;
    });
};

export const localStoredData = async (postId) => {
  // Retrieve locally saved data
  const storedData = localStorage.getItem('postResult');
  let deleteData = true;
  let savingOfflineText = 'Offline saved data uploaded successfully'

  if (storedData) {
    const parsedData = JSON.parse(storedData);

    for (const result of parsedData) {
      try {
        const statusCode = await postSurveyResult(postId, result);
        console.log('POST request for stored data successful. Status code:', statusCode);
        savingOfflineText = 'Offline saved data uploaded successfully'
      } catch (error) {
        console.log('POST request for stored data failed:', error);
        savingOfflineText = 'You are offline, please try again later! Data will be kept stored offline'
        deleteData = false;
      }
    }

    if (deleteData) {
      // Clear the locally saved data only if all requests succeeded
      localStorage.removeItem('postResult');
    }
  } else {
    savingOfflineText = 'No offline data to be uploaded'
  }

    window.alert(savingOfflineText);
};

const saveSurveyResult = async (postId, surveyResult, setSavingText) => {
  try {
    const statusCode = await postSurveyResult(postId, surveyResult);
    console.log('POST request successful. Status code:', statusCode);
    setSavingText(savingTextSuccess);

    // Retrieve locally saved data
    const storedData = localStorage.getItem('postResult');
    let deleteData = true;

    if (storedData) {
      const parsedData = JSON.parse(storedData);
      // Call the function for each element in the list
      parsedData.forEach(async (result) => {
        try {
          const statusCode = await postSurveyResult(postId, result);
          console.log('POST request for stored data successful. Status code:', statusCode);
          setSavingText(savingTextSuccess);
          // Handle the status code as needed
        } catch (error) {
          console.log('POST request for stored data failed:', error);
          setSavingText(savingTextError);
          deleteData = false;
        }
      });
      if (deleteData) {
        // Clear the locally saved data only if all requests succeeded
        localStorage.removeItem('postResult');
      }
    }
  } catch (error) {
    console.log('POST request failed:', error);
    setSavingText(savingTextError);

    // Retrieve locally saved data
    const storedData = localStorage.getItem('postResult');

    if (storedData) {
      // Data exists, append the new value to the list and save it
      const parsedData = JSON.parse(storedData);
      parsedData.push(surveyResult);
      localStorage.setItem('postResult', JSON.stringify(parsedData));
    } else {
      // No data exists, create a new list with the value and save it
      const newData = [surveyResult];
      localStorage.setItem('postResult', JSON.stringify(newData));
    }
  }
};

const SurveyComponent = ({ surveyId, surveyPostId }) => {
  const [json, setJson] = useState({});
  const [survey, setSurvey] = useState(null);
  const [completed, setCompleted] = useState(false);
  const [savingText, setSavingText] = useState('This survey has been saved. You can start a new one now.')

  useEffect(() => {
    if (surveyId != null && surveyPostId != null) {
      setJson({
        surveyId: surveyId,
        surveyPostId: surveyPostId,
      });
    }
  }, [surveyPostId, surveyId]);

  useEffect(() => {
    if (json.surveyId != null && json.surveyPostId != null) {
      const surveyModel = new Model(json);
      setSurvey(surveyModel);
      // Configuring survey model options
      surveyModel.onPartialSend.add(function(sender){
        saveSurveyData(sender);
      });

      surveyModel.onComplete.add((sender, options) => {
        saveSurveyResult(json.surveyPostId, sender.data, setSavingText);
        setCompleted(true);

        // Get the current value of "postResult" from localStorage
        const postResult = localStorage.getItem("postResult");
        
        // Clear all items from localStorage
        localStorage.clear();
        
        // Restore the "postResult" item in localStorage
        if (postResult) {
          localStorage.setItem("postResult", postResult);
        }

      });

      surveyModel.sendResultOnPageNext = true;
      var prevData = window.localStorage.getItem('survey_partial_data') || null;
      if(prevData) {
          var data = JSON.parse(prevData);
          surveyModel.data = data;
          if(data.pageNo) {
            surveyModel.currentPageNo = data.pageNo;
          }
      }
    }
  }, [json]);


  const handleClose = () => {
    setCompleted(false);
  };

  const handleRestart = () => {
    setCompleted(false);
    
    // Get the current value of "postResult" from localStorage
    const postResult = localStorage.getItem("postResult");
    
    // Clear all items from localStorage
    localStorage.clear();
    
    // Restore the "postResult" item in localStorage
    if (postResult) {
      localStorage.setItem("postResult", postResult);
    }
  
    // Clear the survey
    if (survey) {
      survey.clear();
    }
  };

  function saveSurveyData(survey) {
      var data = survey.data;
      data.pageNo = survey.currentPageNo;
      window.localStorage.setItem('survey_partial_data', JSON.stringify(data));
  }

  return (
    <div className="survey-container">
      {survey && <Survey model={survey} />}
      {completed && (
        <div className="completion-prompt">
          <div className="completion-message">
            <p>{savingText}</p>
            <button className="restart-button" onClick={handleRestart}>
            Start a New Survey
            </button>
            <button className="close-button" onClick={handleClose}>
              &#x2715;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SurveyComponent;
